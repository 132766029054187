@use "sass:color";
// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
// $base-font-family: 'Open Sans', sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;

$brand-color:       #69a9b2;
$brand-color-light: color.adjust($brand-color, $lightness: 40%);
$brand-color-dark: color.adjust($brand-color, $lightness: -25%);

$grey-color:       #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);
